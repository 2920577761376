@use '../../styles/yr' as *;

.last-updated {
  display: flex;
  align-items: center;

  @include mq-lte($mq-675) {
    flex-direction: column;
    align-items: flex-start;
  }
}
