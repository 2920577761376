@use '../../styles/yr' as *;

$progress-primary: var(--color-fill-active);
$progress-secondary: var(--color-fill-subtle-gray);

$mq-details-single-row-breakpoint-gt: 650px;
// The NowHero should switch to showing the larger design with the white cards
// at the same time as the LocationHeader increases menu link padding.
$mq-white-cards-breakpoint-gt: 1030px;
$mq-large-values-breakpoint-gt: 1200px;
$slide-min-height: rem(150px);

// The forecast values in the next hour slide should be larger on larger screen sizes.
// We are using mixins directly here instead of the <Text> component since we need responsivity in the text sizes
@mixin scalable-hero-font() {
  font-variant-numeric: tabular-nums;

  @include text-4;

  @include mq-gt($mq-details-single-row-breakpoint-gt) {
    @include text-2;
  }

  @include mq-gt($mq-large-values-breakpoint-gt) {
    @include hero-2;
  }
}

.now-hero {
  position: relative;
  z-index: 0;

  &:after {
    background-image: linear-gradient(
      var(--color-background-gradient-now-bg-now-bg-gradient-stop),
      var(--color-background-gradient-now-bg-now-bg-gradient-start)
    );
    bottom: size(6);
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    // HACK(scb): Safari on iOS (tested on iOS 13.2) renders a 1px tall
    // horizontal line at the bottom of the linear gradient image.
    // We use clip path to hide this line.
    clip-path: polygon(0% 0%, 100% 0%, 100% 99%, 0% 99%);
  }

  @include mq-gt($mq-white-cards-breakpoint-gt) {
    padding: size(6) 0;

    &.now-hero--is-coordinate {
      padding-top: 0;
    }

    &:after {
      bottom: 0;
    }
  }
}

.now-hero--show-map {
  &:after {
    display: none;
  }

  @include mq-gt($mq-white-cards-breakpoint-gt) {
    padding-top: 0;

    .now-hero__slide-container {
      margin-top: (-$slide-min-height * 0.5);
    }
  }
}

.now-hero__static-map {
  display: block;
  position: relative;
  max-width: rem(1440px);
  height: rem(166px);
  margin: 0 auto;
  overflow: hidden;

  @include mq-gt($mq-white-cards-breakpoint-gt) {
    height: rem(328px);

    // OVERRIDE(scb): Shift the map up slightly so the pin is centered
    // in the part of the map that is visible above the now hero cards.
    // We do this by shifting the map up by half of the part of the map
    // that is covered by the now hero cards.
    .static-map {
      top: -$slide-min-height * 0.25;
    }
  }
}

.now-hero__slide-container {
  @include hide-overflow-scrollbars;

  display: flex;

  padding-bottom: size(1);

  // Prevent accidentally navigating to the previous or next page
  // when scrolling the now hero.
  overscroll-behavior: contain;

  // Make the slides scrollable on small screens
  overflow-x: auto;

  // Necessary to make overflow scrolling work in Safari
  -webkit-overflow-scrolling: touch;

  // 2015 spec
  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);

  // 2018 spec
  scroll-snap-type: x mandatory;

  @include mq-gt($mq-white-cards-breakpoint-gt) {
    // On larger screens the cards shouldn't be scrollable. We could have just kept the `overflow-x: auto` rule
    // since the cards wouldn't be scrollable since the slides fit within the slide container,
    // but the overflow auto rule also crops box shadows so we explicitly set `overflow-x` to `visible`.
    overflow-x: visible;

    // On larger screens we use grid instead of flexbox because the nowcast slide
    // is not shown for locations without nowcast, but we still want the first slide
    // to take up the same amount of space as it would had the nowcast slide been visible,
    // which is easier to do with grid.
    display: grid;

    // We need to use empty rows and columns to add gaps between the cards
    // because `column-gap` isn't supported in IE10, IE11 and non-Chrome versions of Edge.
    grid-template-columns: 2fr size(6) 1fr;
  }
}

.now-hero__slide {
  min-height: $slide-min-height;
  min-width: 100%;
  padding: size(2) size(2) size(2) size(2);
  scroll-snap-align: center;

  // Contain z indexes
  position: relative;
  z-index: 0;

  @include mq-gt($mq-white-cards-breakpoint-gt) {
    background-color: var(--color-background-elevated);
    border-radius: 8px;
    box-shadow: $theme-box-shadow;
    min-width: auto;
    padding: size(2) size(4) size(3) size(4);
  }
}

.now-hero__slide--next-hour {
  grid-column: 1;
}

.now-hero__slide--now-graph {
  grid-column: 3;

  // Show the now graph as as the first slide
  .now-hero--show-now-graph-as-first-slide & {
    @include mq-lte($mq-white-cards-breakpoint-gt) {
      order: -1;
    }
  }
}

.now-hero__heading {
  margin: 0 0 size(2) 0;
  padding: 0 size(1.5) 0 size(3);
  position: relative;
}

.now-hero__heading-icon {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.now-hero__progress {
  bottom: 0;
  display: flex;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  // The progress indicator is not needed when we show the slides
  // as white cards.
  @include mq-gt($mq-white-cards-breakpoint-gt) {
    display: none;
  }

  // The progress buttons are only meaningful if the application is running
  .no-js & {
    display: none;
  }
}

.now-hero__progress-button {
  background-color: transparent;
  border: 0;
  height: rem(44px);
  padding: 0;
  position: relative;
  width: rem(44px);

  &:after {
    background-color: $progress-secondary;
    border-radius: 50%;
    content: '';
    display: block;
    height: rem(6px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: rem(6px);
  }

  &:first-child:after {
    right: size(1) * 0.5;
  }

  &:last-child:after {
    left: size(1) * 0.5;
  }

  &[disabled]:after {
    background-color: $progress-primary;
  }
}

.now-hero__next-hour-content {
  align-items: center;
  display: flex;
}

.now-hero__next-hour-main {
  align-items: center;
  display: flex;
  flex: 1;

  // Make the main element as wide as its content
  @include mq-gt($mq-details-single-row-breakpoint-gt) {
    flex: none;
  }
}

.now-hero__next-hour-symbol {
  height: rem(60px);
  position: relative;
  vertical-align: top;
  width: rem(60px);

  & > .weather-symbol {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.now-hero__next-hour-temperature-main {
  @include hero-2;
  font-variant-numeric: tabular-nums;

  margin-left: size(1);

  // Show the temperature value as part of the temperature text
  @include mq-gt($mq-details-single-row-breakpoint-gt) {
    display: none;
  }
}

.now-hero__next-hour-details {
  flex: 1;

  @include mq-gt($mq-details-single-row-breakpoint-gt) {
    align-self: flex-end;
    display: flex;
  }
}

.now-hero__next-hour-detail {
  margin-bottom: size(1);
  position: relative;
  margin-left: size(4);

  @include mq-lte($mq-details-single-row-breakpoint-gt) {
    display: flex;
  }

  @include mq-gt($mq-details-single-row-breakpoint-gt) {
    align-items: flex-end;
    display: flex;

    margin-bottom: size(0.5);
  }
}

.now-hero__next-hour-icon {
  align-self: center;
  height: size(3);
  width: size(3);
  margin-right: size(1);

  @include mq-gt($mq-large-values-breakpoint-gt) {
    margin-top: size(0.5);
  }

  @include mq-lte($mq-details-single-row-breakpoint-gt) {
    height: size(2);
    width: size(2);
  }
}

.now-hero__next-hour-text {
  display: inline-block;
}

.now-hero__next-hour-temperature-text {
  display: none;

  @include scalable-hero-font();

  @include mq-gt($mq-details-single-row-breakpoint-gt) {
    display: inline;
  }
}

.now-hero__next-hour-precipitation-value {
  @include scalable-hero-font();
}

.now-hero__next-hour-wind {
  font-variant-numeric: tabular-nums;

  .wind__value {
    @include scalable-hero-font();
  }
}

.now-hero__now-graph {
  margin-top: size(3);

  @include mq-gt($mq-large-values-breakpoint-gt) {
    margin-top: 0;
  }
}

.now-hero__no-precipitation,
.now-hero__radar-error {
  color: var(--color-text-subtle);
  margin-top: size(4);
  text-align: center;
}

.now-hero__now-graph-info-button-wrapper {
  position: absolute;
  right: size(2);
  top: size(2);

  .no-js & {
    display: none;
  }
}
