@use '../../styles/yr' as *;

.now-graph {
  padding: 0 size(3);
  position: relative;
}

.now-graph__x-axis {
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: size(3);
  position: absolute;
  right: size(3);
}

.now-graph__x-axis-tick {
  height: 100%;
  position: relative;
  top: 0;

  &:after {
    background-color: var(--color-stroke-muted-gray);
    content: '';
    display: block;
    height: rem(4);
    left: 50%;
    position: absolute;
    top: rem(2);
    width: 1px;
  }
}

.now-graph__x-axis-label {
  left: 50%;
  position: absolute;
  top: size(1);
  transform: translateX(-50%);
  white-space: nowrap;
}

.now-graph__y-axis-container {
  left: 0;
  position: absolute;
  top: 0;
}

.now-graph__y-axis {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.now-graph__y-axis-tick {
  color: var(--color-fill-subtle);
  height: 1px;
  position: relative;
  width: 100%;
}

.now-graph__y-axis-symbol {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.now-graph__graph-content {
  overflow: hidden; // Graph may draw with negative values on y-axis, so hide those
  position: relative;
}

.now-graph__graph {
  display: block;
  width: 100%;
}

.now-graph__grid-lines {
  stroke: var(--color-stroke-muted-gray);
  mix-blend-mode: multiply;
}
