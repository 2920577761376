@use '../../styles/yr' as *;

.static-map {
  position: relative;
  width: 100%;
  height: 100%;
}

.static-map__map {
  width: 100%;
  height: 100%;
}

.static-map__pin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: size(3);
  height: size(3);
  transform: translate(-50%, calc(-100% + #{rem(2px)}));

  @include mq-gt(500px) {
    width: size(5);
    height: size(5);
    transform: translate(-50%, calc(-100% + #{rem(3px)}));
  }
}
