@use '../../styles/yr' as *;

.forecast-page__view-switcher {
  margin: 0 auto;
  margin-bottom: size(1);

  @include mq-gt($mq-675) {
    margin-bottom: size(2);
  }
}

.forecast-page__footer {
  display: flex;
  flex-direction: column;
  gap: size(1);

  padding: size(2);
}

.forecast-page__save-as {
  display: flex;
  gap: size(2);
}
