@use '../../styles/yr' as *;

@keyframes pulse {
  0% {
    transform: scale(0.1);
    opacity: 0.5;
  }
  30% {
    transform: scale(0.5909);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.pulse-icon {
  height: size(2);
  position: relative;
  width: size(2);

  &:before,
  &:after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--color-fill-active);
    border-radius: 50%;
    content: '';
    animation-name: pulse;
    animation-duration: 2250ms;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.1, 0.24, 0.565, 0.905); // ease-out
  }

  &:after {
    animation-delay: -1125ms;
  }
}
