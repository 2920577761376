@use '../../styles/yr' as *;

$view-switcher-background-color: var(--color-background-muted-gray);
$view-switcher-text: var(--color-text-subtle);
$view-switcher-hover-text: var(--color-text-active);
$view-switcher-active-text: var(--color-text-active);

.view-switcher--compact {
  text-align: center;
}

.view-switcher__content {
  display: flex;
  text-align: center;
  border: solid size(0.5) $view-switcher-background-color;
  border-radius: size(1);
  background-color: $view-switcher-background-color;

  .view-switcher--compact & {
    display: inline-flex;
    max-width: 100%;
    width: rem(302px);
  }
}

.view-switcher--hidden {
  visibility: hidden;
}

.view-switcher__item {
  flex: 1 1 0;
  height: rem(34px);
  line-height: rem(34px);
  text-decoration: none;
  padding: 0 size(1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-text-subtle);

  @include hover {
    color: $view-switcher-hover-text;
  }
}

.view-switcher__item--active {
  background-color: var(--color-background-base);
  border-radius: rem(6px);
  box-shadow: $theme-box-shadow;
  color: $view-switcher-active-text;
}
