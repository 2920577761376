@use '../../styles/yr' as *;

.info-panel {
  margin: 0;
  border-radius: size(1);
  padding: size(2) size(2);
  background-color: var(--color-background-elevated);
}

.info-panel--large {
  max-width: rem(660px);
}

// This is added to support an edge case of the InfoPanel that is used in CoastHeader for the coast page
.info-panel--medium {
  max-width: rem(416px);
}

.info-panel__header-image-container {
  position: relative;

  // Escape padding from info-panel
  margin: size(-2) size(-2) size(4) size(-2);
}

.info-panel__header-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  // Image needs the same border-radius as OverlayPanel
  border-top-left-radius: size(0.5);
  border-top-right-radius: size(0.5);
}

.info-panel__header {
  margin-bottom: size(2);
  padding-right: size(1) + rem(24px);
}
.info-panel__header-wrapper {
  display: flex;
  flex-direction: column;
}

.info-panel__body {
  & > * + * {
    margin-top: size(1);
  }
}

.info-panel__close-button {
  position: absolute;
  right: size(1);
  top: size(1);
}
