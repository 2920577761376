// Hide horizontal/vertical scrollbars from elements with overflow scrollbars.
// This should only be used if it is possible to access and scroll the content
// in some other way, e.g. by clicking a button.
@mixin hide-overflow-scrollbars() {
  .js & {
    // Hide the scrollbar in browsers that support `scrollbar-width`
    scrollbar-width: none;

    // Hide the scrollbar in Safari and Chrome
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
}
